import React from 'react'
import AboutImg from '../images/about.jpg'

function About() {
  return (
    <div className='container mx-auto w-screen py-16 shadow-xl'>
        <h1 className='text-4xl text-center py-3 font-bold'>Jaana Erkkilä-Hill</h1>
        <div className='flex flex-wrap'>
          <div className='md:w-1/4'>
              <img className='rounded' src={AboutImg} alt='about'></img>
          </div>
          <div className='md:w-3/4 pl-2'>
            <p>
            Kiihkeästi teknologistuvan maailman myllerryksissä yhteys luontoon tuntuu minulle henkilökohtaisesti yhä merkityksellisemmältä, suorastaan elinehdolta. Jollekin luonto on puutarha, kasvi ikkunalla, ja jollekin se on runsaudensarvi puineen, hyötykasveineen, laajoine erämaineen, metsineen, vesineen, villeine eläimineen. Minulle se on kaikkea tätä, ja myös yhteyttä mielikuvituksen todellisuuteen, jossa asustavat ikiaikaiset oudot olennot taivaan tanhuvilla ja syvällä maan sisällä, merien uumenissa.
            </p>
            <br></br>
            <p>
            Käsityöhön perustuva puupiirros- ja linokaiverrus on tapani ajatella ja tehdä sisäistä maailmaani näkyväksi ja toisten kanssa jaettavaksi. Teokseni ovat eräänlaista viittaustaidetta. Teen havaintoja ympäristöstäni, lainaan lukemistani kirjoista, viittaan taide- ja kulttuurihistoriallisiin kuviin, ja rakennan kaikesta tästä eräänlaista tilkkutäkkiä, mielen karttaa, jossa toisiinsa varsinaisesti liittymättömät asiat muodostavat uusia, itsellenikin yllättäviä kokonaisuuksia.
            </p>
            <br></br>
            <p>
            Elän ja teen työtä kahdessa maassa, Suomessa ja Englannissa, joissa käsitys luonnosta eroaa toisistaan kohtuullisen paljon. Myös kasvillisuus on erilaista ja villin luonnon saavutettavuus poikkeaa toisistaan. Suomessa, Euroopan pohjoisimmassa koilliskolkassa, on meillä kaikilla mahdollista rakentaa ainutlaatuinen, yksinkertainen luontosuhde asummepa kaupungissa tai maaseudulla. Tämä ei ole itsestäänselvyys kaikkialla maailmassa, ja toivottavasti tämä etuoikeus ei ole meilläkään katoavaa ja muutu tulevaisuudessa yhdeksi muistoksi menneisyydestä.
            </p>
            <br></br>
            <p>
            Helvi Juvosen sanoin kohotan maljan kaikelle elolliselle: ”Jäkälä nosti pikarinsa hauraan: nyt malja elämämme rikkaudelle.”
            </p>
          </div>
        </div>
    </div>
  )
}

export default About