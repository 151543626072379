// Components
import Navbar from "./components/Navbar";
// Pages
import Home from "./pages/Home";
import Exhibitions from "./pages/Exhibitions";
import Works from "./pages/Works";
import About from "./pages/About";
import Essays from "./pages/Essays";
// Router
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


function App() {
  return (
    <Router>
      <div className="bg-white">
        <Navbar></Navbar>
        <div className="container mx-auto">
          <Routes>
            <Route path='/' element={
              <>
                <Home></Home>
              </>
            }></Route>
            <Route path='/exhibitions' element={
              <Exhibitions></Exhibitions>
            }>
            </Route>
            <Route path='/works' element={
              <Works></Works>
            }></Route>
            <Route path='/about' element={
              <About></About>
            }></Route>
            <Route path='/essays' element={
              <Essays></Essays>
            }>
            </Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
