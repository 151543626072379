import React from 'react'
// Images -_-
import FromUnderTheNorthStar from '../images/From-under-the-North-Star.jpg'
import AjatusAaltoja from '../images/ajatusaaltoja.jpeg'
import RauhanValtakunta from '../images/rauhan valtakunta.jpeg'
import SeeingHands from '../images/SeeingHands.jpg'
import KsenianMatka from '../images/KsenianMatka.jpg'
import KysyElaimilta from '../images/kysyelaimilta.jpeg'
import Kannattelija from '../images/Kannattelija-2.jpg'
import Encounters from '../images/Encounters.jpg'
import Taivaallista from '../images/taivaallista musiikkia 1.jpg'
import Taivaallistax from '../images/taivaallista musiikkia 2.jpg'
import VuoteemmeVirta from '../images/vuoteemmeVirta.jpg'
import Kotka from '../images/kotka.jpeg'
import { CarouselItem } from 'react-bootstrap'
import { Carousel } from 'react-bootstrap'
// bstrap
//import 'bootstrap/dist/css/bootstrap.min.css';



function PreviewCarousel() {

  return (
    <>
      <Carousel fade className='py-2 shadow-xl w-full mx-auto'>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={Encounters} alt='first'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={Taivaallista} alt='second'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={Taivaallistax} alt='third'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={VuoteemmeVirta} alt='thirdx'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={FromUnderTheNorthStar} alt='fourth'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={AjatusAaltoja} alt='fifth'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={RauhanValtakunta} alt='sixth'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={SeeingHands} alt='seventh'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={KsenianMatka} alt='eigth'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={KysyElaimilta} alt='ninth'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={Kannattelija} alt='tenth'></img>
          </CarouselItem>
          <CarouselItem>
              <img className='mx-auto w-96 h-64' src={Kotka} alt='eleventh'></img>
          </CarouselItem>
      </Carousel>
    </>
  )
}

export default PreviewCarousel