import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'



function Navbar() {
  return (
    <div className='navbar bg-base-100 z-50 shadow-xl w-screen fixed'>
        <div className='flex w-screen'>
            <div className='dropdown'>
                <label tabIndex="0" className="btn btn-ghost lg:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                </label>
                <ul tabIndex='0' className='menu menu-compact dropdown-content z-50 mt-3 shadow bg-base-100 rounded-box'>
                <li tabIndex='0'>
                    <a href='/exhibitions'>Exhibitions
                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
                    </a>
                    <ul className='p-2 bg-base-100 border border-indigo-500 border-round divide-y divide-slate-700'>
                        <li>
                            <a href='/exhibitions#weirdAndWonderful' className=''>
                                Weird and wonderful life
                            </a>
                        </li>
                        <li>
                            <a href='/exhibitions#lifeOfRomu' className=''>
                                Life of Romu
                            </a>
                        </li>
                    </ul>
                </li>
                <li tabIndex='0'>
                    <a href='/works'>Works
                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
                    </a>
                    <ul className='p-2 bg-base-100 border border-indigo-500 divide-y divide-slate-700'>
                        <li>
                            <Link to='/works#woodcuts' className=''>
                                Woodcuts in color
                            </Link>
                        </li>
                        <li>
                            <Link to='/works#peaceable' className=''>
                                The Peacable Kingdom
                            </Link>
                        </li>
                        <li>
                            <Link to='/works#askTheAnimals' className=''>
                                Ask the animals
                            </Link>
                        </li>
                    </ul>
                </li>
                <li><a href='/essays'>Essays</a></li>
                <li><a href='/about'>About</a></li>
                </ul>
                
            </div>
            <Link to='./' className='lg:absolute pl-5 lg:pl-0 lg:left-10 text-center hover:text-black'>Jaana Erkkilä-Hill</Link>
            <div className='hidden lg:flex mx-auto'>
                    <ul className='menu menu-horizontal p-0 z-50'>
                        <li tabIndex='0'>
                            <a className='hover:text-black' href='/exhibitions'>Exhibitions
                            <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
                            </a>
                            <ul className='p-2 bg-base-100 border border-indigo-500 border-round divide-y divide-slate-700'>
                                <li>
                                    <Link to='/exhibitions#weirdAndWonderful' className='hover:text-black'>
                                        Weird and wonderful life
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/exhibitions#lifeOfRomu' className='hover:text-black'>
                                        Life of Romu
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li tabIndex='0'>
                            <a className='hover:text-black' href='/works'>Works
                            <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
                            </a>
                            <ul className='p-2 bg-base-100 border border-indigo-500 divide-y divide-slate-700'>
                                <li>
                                    <Link to='/works#woodcuts' className='hover:text-black'>
                                        Woodcuts in color
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/works#peaceable' className='hover:text-black'>
                                        The Peacable Kingdom
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/works#askTheAnimals' className='hover:text-black'>
                                        Ask the animals
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li><a href='/essays'>Essays</a></li>
                        <li><a href='/about'>About</a></li>
                    </ul>
            </div>
        </div>
    </div>
  )
}

export default Navbar