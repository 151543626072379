import React from 'react'

// Images
import SeeingHands from '../images/SeeingHands.jpg'
import KsenianMatka from '../images/KsenianMatka.jpg'
import LifeOfRomu from '../images/LifeofRomu280.jpg'
import Kannattelija from '../images/Kannattelija-2.jpg'
import Village from '../images/Village280.jpg'
import FromUnderTheNorthStar from '../images/From-under-the-North-Star.jpg'


function Exhibitions() {
    
  return (
    <div className='container mx-auto w-screen py-16'>
        <h1 className='text-4xl text-center py-3 font-bold' id='weirdAndWonderful'>Exhibitions</h1>
        <div className='w-full shadow-md px-2'>
            <div className='title'>
                <h1 className='text-2xl font-bold block'>Weird and wonderful life</h1>
            </div>
            <div>
                <p>
                    24.1.-31.5.2020, Korundi – House of Culture, Rovaniemi
                </p>
                <br></br>
                <p>
                    In Jaana Erkkilä’s color as well as black and white woodcuts, life seems strange and wonderful. Erkkilä’s works often originate from everyday observations and events, landscapes, read literature and discussions.
                </p>
                <br></br>
                <p>
                    Visual Artist, Doctor of Arts, Jaana Erkkilä (b. 1960, Kokkola), the everyday and the sacred, visible and invisible world comes together. Weird and Wonderful Life is the artist’s 60th anniversary exhibition where people, animals, heavenly and earthly creatures roam the surface of paper and cloth. One of the central characters in the works is Saint Xsenia of Saint Rovaniemen taidemuseo Petersburg, originally named Ksenia Grigorievna Petrova, a canonized saint of the Orthodox Church. Ksenia was God’s fool by giving away her possessions and living homeless in the streets of St. Petersburg.
                </p>
                <br></br>
                <p>
                    While working on colored woodcuts is slow and requires patience, small engraving works relaxes the artist and keeps the mind light. The exhibition also features the graphic artist’s living room, a prints-walled space where the viewer is inside the work, being observed by the pictures on the walls.
                </p>
                <br>
                </br>
                <p>
                    Jaana Erkkilä works as Vice Rector of the University of the Arts Helsinki. She graduated with a Master of Fine Arts from the Academy of Fine Arts in 2000 and a Doctor of Arts from Aalto University in 2012. In addition to her academic career, she is active as an artist and her works are known for example in the Jenny and Antti Wihuri Foundation Collection.
                </p>
            </div>
            <div className='flex flex-wrap space-x-0 md:space-x-2 py-2'>
                <div>
                    <img className='rounded' src={SeeingHands} alt='seeing hands'>
                    </img>
                    <p className='text-center'>Näkevät kädet / Seeing hands 2019</p>
                </div>
                <div>
                    <img className='rounded' src={KsenianMatka} alt='ksenian matka'></img> 
                    <p className='text-center'>Ksenian matka 2020</p>
                </div>
            </div>
        </div>
        <div className='anchor py-4' id='lifeOfRomu'></div>
        <div className='w-full shadow-md px-2 py-4'>
            <div className='title'>
                <h1 className='text-2xl font-bold block'>Life of Romu – A series of linocuts based on life of my free spirited dog Romu</h1>
            </div>
            <div>
                <p>
                We have three mongrel dogs in our family. The oldest one is my beloved dog Romu. 
                </p>
                <br></br>
                <p>
                He is friendly, happy, full of tricks and fun – and he loves running free and making an escape out of the garden whenever a possibility arises. Everybody in the neighbourhood knows Romu. The dog catcher knows him too, but Romu has grown too old and wise to let him come too close and catch him. In Finland we have “dog cathcers”, people who are employed by municipality to catch loose running dogs. 
                </p>
                <br></br>
                <p>
                After taking a good run in town, by a canal and in local parks he comes home and barks behind the door: back home, let me in! Then he eats and takes a good sleep.
                </p>
            </div>
            <div className='flex flex-wrap space-x-0 md:space-x-2 py-2'>
                <div>
                    <img className='rounded' src={LifeOfRomu} alt='life of romu'>
                    </img>
                    <p className='text-center'>Life of Romu</p>
                </div>
            </div>
        </div>
        <div className='w-full shadow-md px-2 py-4'>
            <div className='title'>
                <h1 className='text-2xl font-bold block'>Carrying the World - Colours as a way to talk about atmosphere</h1>
            </div>
            <div>
                <p>
                Colours have always been essential for me. I cannot wear sunglasses, because when having them on everything turns into strange colours and tones, brown or grey when it should be bright and light. I feel that I miss something important in a landscape, if I let lenses come between me and my surroundings.
                </p>
                <br></br>
                <p>
                    When I started art school I studied painting. But then after graduating from university I had a chance to stay one extra year and I decided to go for printmaking. It changed my concept of colour. I fell in love with working on woodcuts and printing thin layers of colour one on top of the other. After thirty years of printmaking I still get excited about colour. I like warm colours, dark colours, the atmosphere of evening.
                </p>
            </div>
            <div className='flex flex-wrap space-x-0 md:space-x-2 py-2'>
                <div>
                    <img className='rounded' src={Kannattelija} alt='carrying the world'>
                    </img>
                    <p className='text-center'>Kannattelija / Carrying the World 2018</p>
                </div>
                <div>
                    <img className='rounded' src={Village} alt='village'>
                    </img>
                    <p className='text-center'>Village</p>
                </div>
            </div>
            <div>
                <p>
                I have named the series of colourful woodcuts “From under the North Star”. The North Star has a special meaning for people in the North, especially in the Arctic. The North Star leads you home, it shows the way. 
                </p>
                <br></br>
                <p>
                We love outdoor life, forest, enormous sky above us. We are born under the North Star and there is a saying “we come to life under the North Star and there is where we leave this world”. It is home.
                </p>
            </div>
            <div className='flex flex-wrap space-x-0 md:space-x-2 py-2'>
                <div>
                    <img className='rounded' src={FromUnderTheNorthStar} alt='from under the north star'>
                    </img>
                    <p className='text-center'>From under the North star</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Exhibitions