import React from 'react'
import SlowThinking from '../files/slowthinking.pdf'
import TaitelijaPedagogiikka from '../files/taiteilijapedagogiikka.pdf'
import WalkingMiraclesPDF from '../files/walkingmiracles.pdf'
import WalkingMiracles from '../images/walkingmiracles'
import Hill from '../images/Erkkilä-Hill_009_kuva9.jpeg'
import Hill2 from '../images/Erkkilä-Hill_009_kuva8.JPG'
import Kirje from '../files/KirjeTyyneEskolle.pdf'
import KuvaKirje from '../files/KuvakirjeKsenia.pdf'
import SoS from '../files/SoundOfSilence.pdf'
import Puut from '../images/puutpurjeina.jpg'
import Kotka from '../images/kotka.jpeg'
import Village from '../images/Village280.jpg'

function Essays() {
  return (
    <div className='container mx-auto w-screen py-16 shadow-xl'>
        <h1 className='text-4xl text-center py-3 font-bold'>Essays</h1>
            <div className='w-full space-x-0 flex flex-wrap sm:space-x-0  justify-center'>
              <div className='card w-full sm:w-64 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={Hill2} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Slow thinking laboratory
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <a href={SlowThinking} className='btn btn-success btn-outline'>Open</a>
                  </div>
                </div>
              </div>
              <div className='card w-full sm:w-64 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={Hill} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Artist pedagogy
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                  <a href={TaitelijaPedagogiikka} className='btn btn-success btn-outline'>Open</a>
                  </div>
                </div>
              </div>
              <div className='card w-full sm:w-64 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={WalkingMiracles} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Walking miracles
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <a href={WalkingMiraclesPDF} className='btn btn-success btn-outline'>Open</a>
                  </div>
                </div>
              </div>
              <div className='card w-full sm:w-64 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={Puut} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Sound of silence
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <a href={SoS} className='btn btn-success btn-outline'>Open</a>
                  </div>
                </div>
              </div>
              <div className='card w-full sm:w-64 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={Kotka} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Kirje Tyyne Eskolle
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <a href={Kirje} className='btn btn-success btn-outline'>Open</a>
                  </div>
                </div>
              </div>
              <div className='card w-full sm:w-64 h-64 bg-base-100 image-full px-0 mx-0'>
                <figure className='h-64'><img className='w-full' src={Village} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Kuvakirje Pietarilaisille
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <a href={KuvaKirje} className='btn btn-success btn-outline'>Open</a>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default Essays