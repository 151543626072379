import React from 'react'
import Carousel from '../components/PreviewCarousel'
import LifeOfRomu from '../images/LifeofRomu280.jpg'
import SeeingHands from '../images/SeeingHands.jpg'
import Village from '../images/Village.jpg'
import AsABaby from '../images/as-a-baby.jpeg'
import WalkingMiracles from '../images/walkingmiracles'
import Hill from '../images/Erkkilä-Hill_009_kuva9.jpeg'
import Hill2 from '../images/Erkkilä-Hill_009_kuva8.JPG'

import SlowThinking from '../files/slowthinking.pdf'
import TaitelijaPedagogiikka from '../files/taiteilijapedagogiikka.pdf'
import WalkingMiraclesPDF from '../files/walkingmiracles.pdf'

import { HashLink as Link } from 'react-router-hash-link'


function Home() {
  return (
    <>
        <div className='py-8'></div>
        <Carousel></Carousel>
        <div className='shadow-md'>
          <div className='mt-5 pl-5 pr-5'>
            <h1 className='text-2xl pb-2'>Exhibitions</h1>
            <div className='w-full flex flex-wrap md:space-x-2 lg:space-x-2'>
              <div className='card w-full sm:w-1/3 lg:1/4 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={SeeingHands} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Weird and Wonderful Life
                  </h2>
                  <p>
                  Black and white woodcuts, life seems strange and wonderful.
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <Link to='/exhibitions#weirdAndWonderful' className='btn btn-success btn-outline'>View more</Link>
                  </div>
                </div>
              </div>
              <div className='card w-full sm:w-1/3 lg:1/4 sm:pt-0 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={LifeOfRomu} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Life Of Romu
                  </h2>
                  <p>
                  Based on life of Romu
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <Link to='/exhibitions' className='btn btn-success btn-outline'>View more</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-5 pl-5 pr-5'>
            <h1 className='text-2xl pb-2'>Works</h1>
            <div className='w-full flex flex-wrap md:space-x-2 lg:space-x-2'>
              <div className='card w-full sm:w-1/3 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={Village} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Woodcuts in color
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <Link to='/works#woodcuts' className='btn btn-success btn-outline'>View more</Link>
                  </div>
                </div>
              </div>
                <div className='card w-full sm:w-1/3 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={AsABaby} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  The Peaceable Kingdom
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <Link to='/works#peaceable' className='btn btn-success btn-outline'>View more</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='shadow-xl pb-10 mt-5 pl-5 pr-5'>
            <h1 className='text-2xl pb-2'>Essays</h1>
            <div className='w-full space-x-0 flex flex-wrap sm:space-x-0 md:space-x-0 lg:space-x-2'>
              <div className='card w-full sm:w-64 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={Hill2} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Slow thinking laboratory
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <a href={SlowThinking} className='btn btn-success btn-outline'>Open</a>
                  </div>
                </div>
              </div>
              <div className='card w-full sm:w-64 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={Hill} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Artist pedagogy
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <a href={TaitelijaPedagogiikka} className='btn btn-success btn-outline'>Open</a>
                  </div>
                </div>
              </div>
              <div className='card w-full sm:w-64 h-64 bg-base-100 image-full'>
                <figure className='h-64'><img className='w-full' src={WalkingMiracles} alt='img'></img></figure>
                <div className='card-body'>
                  <h2 className='card-title'>
                  Walking miracles
                  </h2>
                  <p>
                  
                  </p>
                  <div className='card-actions justify-left pb-2'>
                    <a href={WalkingMiraclesPDF} className='btn btn-success btn-outline'>Open</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Home